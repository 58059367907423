import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
//import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

import firstCaseMockup from "../images/scoutingmockup1.png";
import firstCaseLogo from "../images/scoutinglogo.svg";
import feraName from "../images/mafer.svg";
import need1 from "../images/need1.png";
import need2 from "../images/need2.png";
import needswritten from "../images/placeneeds.jpg";
import journey from "../images/journey.jpg";
import features from "../images/features.png";
import wire1 from "../images/wire1.jpg";
import wire2 from "../images/wire2.jpg";
import figma1 from "../images/figma1.png";
import homecase1 from "../images/homecase1.png";
import resultscase1 from "../images/resultscase1.png";
import descriptioncase1 from "../images/descriptioncase1.png";
import faqscase1 from "../images/faqscase1.png";
import confirmcase1 from "../images/confirmcase1.png";

function ScoutingPage() {
    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title=""
            />

            <section>
            <Link to="/">
                <div className="relative h-auto w-auto ...">
                    <div>
                        <img
                            alt="Case One"
                            className="md:w-64 object-cover "
                            src={feraName}
                        />
                        {/*   <h2 className=" inline-block p-0.5 px-4 mb-4 leading-tight text-2xl font-semibold tracking-wide text-gray-800 bg-green-200">
          Fernanda Ramírez
        </h2> */}
                    </div>
                    <div className="-m-2">
                        <h2 className="inline-block p-0 px-4 mt-4 mb-4 md:text-1xl font-light text-gray-800 text-sm">
                            Product Designer
        </h2>
                    </div>

                </div>
                </Link>
            </section>

            <section className="relative flex items-center justify-center">
                <div className="md:flex-shrink-0">

                    <img
                        alt="Case One"
                        className="h-16 md:w-40 w-24"
                        src={firstCaseLogo}
                    />
                </div>

            </section>

            <section className="relative flex items-center justify-center">

                <p className="block mt-1 text-lg leading-tight text-gray-600 font-thin">Find locations for your next audiovisual production</p>
            </section>

            <section>
                <div className="md:flex-shrink-0 md:p-12 p-8 pt-16 md:pt-20">


                    <img
                        alt="Case One"
                        className="h-full w-full object-cover "
                        src={firstCaseMockup}
                    />
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-3 gap-2 ">
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Year</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center ">Duration</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Semester</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">2019</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center ">5 months</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">7th</div>
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-1 pt-20 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center ">The context</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2 ">Today, the process of finding a place to film is hard for students and independent film makers. From searching for places in social media, passing through finding a landlord that would be willing to make their place a location and to organizing trips to see if a place actually works or matches their description, a service that simplifies this process can be implemented.</p>
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-1 pt-12 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center pb-6">The process</div>
                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-2/5 w-4/5 bg-green-100 mt-8">1. Identifying the needs</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">When looking for a place to film or take pictures, producers and students start they search by posting pictures and asking for help in social media.</p>


                    <div className="relative flex items-center justify-center grid md:grid-cols-2 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-6 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover "
                                src={need1}
                            />
                        </div>
                        <div className="md:flex-shrink-0 md:p-6 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover "
                                src={need2}
                            />
                        </div>
                    </div>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={needswritten}
                            />
                        </div>

                    </div>

                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">The main steps followed to find a location are: post in social media in groups or stories, get the landlord contact, speak and explain how and when they will be using this place and for what reason, ask if it has what they need, stablish a visit date and set a price and rules of use.</p>

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-3/5 w-auto bg-green-100 mb-8">2. Identifying the people involved (users)</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-16 grid-cols-1 md:p-8 p-2 ">

                        <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
                            <div className="md:flex">

                                <div className="relative p-8">
                                    <div className="tracking-wide px-8 text-sm font-semibold bg-gray-200 mb-4 text-gray-800 w-auto absolute left-0">The ones looking for a place (2)</div>
                                    <br></br>

                                    <div className="relative flex items-center justify-center grid md:grid-cols-2  grid-cols-1">
                                        <div>
                                            <div className="uppercase tracking-wide text-base text-gray-900 font-bold mt-4">The students</div>

                                            <p className="mt-2 font-light text-black">Want a place they can use to film a class project.</p>
                                            <p className="mt-2 text-base font-semibold text-semibold mt-4">Specific features:</p>
                                            <p className="mt-2 font-light text-black ">Has to be close to them.</p>
                                            <p className="mt-1 font-light text-black ">Has to be cheap so they can afford it.</p>
                                            <p className="mt-1 font-light text-black ">Has to be easy to find.</p>

                                        </div>

                                        <div>
                                            <div className="uppercase tracking-wide text-base text-gray-900 font-bold mt-8">The professionals</div>

                                            <p className="mt-2 font-light text-black">Want a place they can use to film something for their job.</p>
                                            <p className="mt-2 font-semibold text-semibold mt-4">Specific features:</p>
                                            <p className="mt-2 font-light text-black ">Can be close to them.</p>
                                            <p className="mt-1 font-light text-black ">Has to fit their budget.</p>
                                            <p className="mt-1 font-light text-black ">Has to be easy to find.</p>
                                            <p className="mt-1 font-light text-black ">Will allow all the crew in.</p>
                                            <p className="mt-1 font-light text-black ">Will be used for long periods of time.</p>
                                            <p className="mt-1 font-light text-black ">Will cover legal matters.</p>

                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>

                        <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
                            <div className="md:flex">

                                <div className="relative p-8">
                                    <div className="tracking-wide px-8 text-sm font-semibold bg-gray-200 mb-4 text-gray-800 w-auto absolute left-0">The ones renting a place</div>
                                    <br></br>
                                    <div className="uppercase tracking-wide text-base text-gray-900 font-bold mt-8">The landords</div>

                                    <p className="mt-2 font-light text-black">Have a place they can rent to film student or producers.</p>
                                    <p className="mt-2 font-semibold text-semibold mt-4">Specific features:</p>
                                    <p className="mt-2 font-light text-black ">Need to know it will be safe.</p>
                                    <p className="mt-1 font-light text-black ">Need to know legal matters will be covered.</p>
                                    <p className="mt-1 font-light text-black ">Need to when, why, who will come and for how long they will stay.</p>

                                </div>

                            </div>
                        </div>

                    </div>

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-green-100 mb-8 mt-8">3. Thinking about the walkthrough</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">Right now, there are 2 ways to find a place: through social media and physically. But what if we add one to simplify it?</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={journey}
                            />

                        </div>



                    </div>

                    <div className=" tracking-wide text-base text-gray-900 font-bold mt-8 md:px-20 px-2">The Platform</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">The main idea behind the platform is to make the process of searching and finding a place to produce simple. The user looks for a place in a certain date, takes a 360° look at the place, takes a look at the dimensios, looks for it in the map and contacts the landord to rent it.</p>

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-green-100 mb-8 mt-8">4. Organizing the features</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={features}
                            />

                        </div>



                    </div>

                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">To make the process fluid, the user only has to log in or register if he is going to rent a place, if he wants to add a place to his wishlist or if he is booking a place to see it before renting.</p>

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-green-100 mb-8 mt-8">5. Creating the platform</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">With the map of the features in mind I satarted creating the wireframes of the platform in a white paper. It was an iterative process where I considered different possible layouts for the platform and I also run some tests to see if the interactions were understandable with some of the possible users. </p>
                    <div className="relative flex items-center justify-center grid md:grid-cols-2 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={wire1}
                            />


                        </div>

                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={wire2}
                            />
                        </div>

                    </div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">And going from the wireframes to Figma I made sure the platform was easy to use both for the ones looking for a location and the ones willing to rent their place. I also made sure the actions and the information did not blend with eachother.</p>

                    <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={figma1}
                            />
                        </div>
                        <div className="tracking-wide text-2xl text-black font-extrabold text-center md:pb-6 md:pt-0 pt-8">The result</div>
                        <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">I finally came up with a fully functional scouting web application with different features for different users.</p>
                        <p className="block text-left mt-1 text-base  text-gray-900 font-light md:px-20 px-2">The home of the site will let everyone search for a place to rent for a production. The platform will filter at the beginning whether the user is a professional or a student in order to set the pricing and benefits of the platform.</p>
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded py-8"
                                src={homecase1}
                            />

                        </div>

                        <p className="block text-left mt-1 text-base  text-gray-900 font-light md:px-20 px-2">For the results page I combined filters, a map and a list of results as cards in order to make the search simple and accesible. The map is draggable and the price, distance, and surface can be filtered from the lowest to the highest value with a slider.</p>
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded py-8"
                                src={resultscase1}
                            />

                        </div>

                        <p className="block text-left mt-1 text-base  text-gray-900 font-light md:px-20 px-2">When clicking on a plcae, the results will open in a detailed way. The landlord will have the change to upload 360° images so that the user can inmerse himself in the experience. The description with the information is placed on the left and the actions are in the right.</p>
                        <p className="block text-left mt-1 text-base  text-gray-900 font-light md:px-20 px-2">I also added the chance to let the landlord know the purpose of the renting and the amount of people that will be part of the crew. </p>
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded py-8"
                                src={descriptioncase1}
                            />

                        </div>

                        <p className="block text-left mt-1 text-base  text-gray-900 font-light md:px-20 px-2">Also, knowing that there is a lot of people willing to rent their place but who do not know what it means or what it implies I added a page where some of these questions are answered.</p>
                        
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded py-8"
                                src={faqscase1}
                            />

                        </div>


                        <p className="block text-left mt-1 text-base  text-gray-900 font-light md:px-20 px-2">Finally, in order for a landlord to confirm his place submission or for a producer or student to rent a place, a credit card number is required to make sure that for none of them this will be a scam.</p>
                        
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8 ">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded py-8 "
                                src={confirmcase1}
                            />

                        </div>

                        <div className="tracking-wide text-xl text-gray-700 font-bold text-center pb-6">Thank you for reading!</div>

                </div>

            </section>

        </Layout>
    );
}

export default ScoutingPage;
